import React from "react";

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", component: React.lazy(() => import("./components/pages/Dashboard")) },
  { path: "/network", component: React.lazy(() => import("./components/pages/Network")) },
  { path: "/hosting", component: React.lazy(() => import("./components/pages/Hosting")) },
  { path: "/wallet", component: React.lazy(() => import("./components/pages/Wallet")) },
  { path: "/bills", component: React.lazy(() => import("./components/pages/Bills")) },
  { path: "/all-bills", component: React.lazy(() => import("./components/pages/Bills")) },
  { path: "/profile", component: React.lazy(() => import("./components/pages/Profile")) },
  { path: "/security", component: React.lazy(() => import("./components/pages/Security")) },
  { path: "/nodes-account", component: React.lazy(() => import("./components/pages/NodesAccount")) },
  { path: "/marketing-tools", component: React.lazy(() => import("./components/pages/MarketingTools")) },
  { path: "/payment-methods", component: React.lazy(() => import("./components/pages/PaymentMethods")) },
  { path: "/subscriptions", component: React.lazy(() => import("./components/pages/Subscriptions")) },
];

export default routes;
